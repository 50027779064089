<template>
  <div class="download-tooltip" :class="position">
    <ul>
      <li class="apk">
        <a href="https://cdn.micoworld.net/apk/mico_release.apk">
          <i class="icon"></i>
          Android APK
        </a>
      </li>
      <li class="ios">
        <a href="https://itunes.apple.com/us/app/mico-meet-new-people-chat/id908023218?mt=8">
          <i class="icon"></i>
          Apple Store
        </a>
      </li>
      <li class="android">
        <a href="https://play.google.com/store/apps/details?id=com.mico">
          <i class="icon"></i>
          Google Play
        </a>
      </li>
      <div class="qr"></div>
      <div class="tip">Scan to download</div>
    </ul>
  </div>
</template>
<script>
  export default {
    props: {
      position: {
        type: String,
        default: 'top'
      }
    },
    data() {
      return {}
    }
  }
</script>
<style lang="less" scoped>
@import "index.less";
</style>
