var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "download-tooltip", class: _vm.position }, [
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", { staticClass: "apk" }, [
        _c(
          "a",
          { attrs: { href: "https://cdn.micoworld.net/apk/mico_release.apk" } },
          [
            _c("i", { staticClass: "icon" }),
            _vm._v("\n        Android APK\n      "),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "ios" }, [
        _c(
          "a",
          {
            attrs: {
              href: "https://itunes.apple.com/us/app/mico-meet-new-people-chat/id908023218?mt=8",
            },
          },
          [
            _c("i", { staticClass: "icon" }),
            _vm._v("\n        Apple Store\n      "),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "android" }, [
        _c(
          "a",
          {
            attrs: {
              href: "https://play.google.com/store/apps/details?id=com.mico",
            },
          },
          [
            _c("i", { staticClass: "icon" }),
            _vm._v("\n        Google Play\n      "),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "qr" }),
      _vm._v(" "),
      _c("div", { staticClass: "tip" }, [_vm._v("Scan to download")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }