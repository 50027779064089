<template>
  <div class="navbar navbar-fixed-top" role="navigation">
    <div class="container hidden-xs">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target=".navbar-collapse">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <a class="navbar-brand" :href="logoLink">
          <img class="logo" :src="logo" alt="logo">
        </a>
      </div>
      <div class="collapse navbar-collapse">
        <ul class="nav navbar-nav navbar-left">
          <li :class="active === 'home' ? 'active' : ''">
            <a :href="homeLink">{{ text.home_page }}</a>
          </li>
          <li v-if="showFeed" :class="active === 'hot' ? 'active' : ''"><a href="/hot">{{ text.hot_live }}</a></li>
        </ul>
        <ul class="nav navbar-nav navbar-right">
          <li v-if="apkLinkShown">
            <a href="javascript:void(0);">
              <i class="app-icon"></i>
              <span>App</span>

              <download-tooltip position="top" class="dt" />
            </a>
          </li>
          <li v-if="showRechargeButton">
            <a href="https://web-app.micoworld.net/web/pay.html">
              <i class="recharge-icon"></i>
              <span>{{ text.recharge }}</span>
            </a>
          </li>
        </ul>
      </div><!--/.nav-collapse -->
    </div>

    <div class="container visible-xs-block mobile">
      <div class="navbar-header">
        <button type="button" style="z-index: 1;" class="navbar-toggle collapsed" data-toggle="collapse" data-target=".navbar-collapse">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <div class="menu" style="position: absolute; width: 100%;">
          <ul class="head-group">
            <li :class="active === 'home' ? 'active' : ''"><a href="/">{{ text.home_page }}</a></li>
            <li v-if="showFeed" :class="active === 'hot' ? 'active' : ''"><a href="/hot">{{ text.hot_live }}</a></li>
          </ul>
          <div v-if="showRechargeButton" class="recharge">
            <a href="https://web-app.micoworld.net/web/pay.html">{{ text.recharge }}</a>
          </div>
        </div>
      </div>
      <div class="collapse navbar-collapse">
        <ul class="nav navbar-nav">
          <li class="languageBtn">
            <a href="javascript:void(0);">{{ language.label || 'English' }}</a>
          </li>
          <li><a href="/terms/terms.html">{{ text.terms }}</a></li>
          <li><a href="/terms/privacy.html">{{ text.privacy }}</a></li>
          <li><a :href="text.about_link">{{ text.about }}</a></li>
        </ul>
      </div><!--/.nav-collapse -->
    </div>
    <!--多语言切换-->
    <ul  class="languageList">
      <li :class="value" v-for="({value, label}) in languages" :key="value">
        <a :class="value" :href="`/?lang=${value}`">{{ label }}</a>
      </li>
      <!--
      <li class="id"><a href="/en/home">English</a></li>
      <li class="zh"><a href="/cn/home">中文</a></li>
      <li class="ar"><a href="/ar/home">اللغة</a></li>
      <li class="th"><a href="/th/home">ภาษา</a></li>
      <li class="tw"><a href="/zh-TW/home">繁體</a></li>
      -->
    </ul>
  </div>
</template>
<script>
  import DownloadTooltip from '../DownloadTooltip'
  export default {
    components: { DownloadTooltip },
    props: {
      active: {
        type: String,
        default: ''
      },
      apkLinkShown: {
        type: Boolean,
        default: true
      },
    },
    data() {
      return {
        showFeed: !!window.showFeed,
        language: {},
        languages: []
      }
    },
    computed: {
      homeLink() {
        return location.pathname.startsWith('/th') ? '/th' : '/'
      },
      showRechargeButton() {
        return window.location.pathname !== '/' && !/(Mac|iPhone|iPod|iPad)/i.test(window.navigator.platform)
      },
      logoLink() {
        return ['live', 'hot'].includes(this.active) ? '/hot' : window.location.href
      },
      text() {
        return window.text
      },
      logo() {
        if (window.location.href.match(/micotm/)) {
          return '//image.micoworld.net/e37f09b1081c084f1932de889c8e0ae5.png'
        } else {
          return '//image.micoworld.net/c4909704820d4ddaff4f5be140315162.png'
        }
      }
    },
    created() {
      this.languages = window.languages.filter((item) => {
        if(item.value === window.document.documentElement.lang) {
          this.language = item
        } else {
          return true
        }
      })
    },
    mounted() {
      $("#micoLanguage").click(function() {
        $("#selectLan li").slideToggle("fast");
        $("#changeTriangle").toggleClass("triangleRotate");
      })

      //展示多语言弹框
      $(".languageBtn").click(function(){
        $(".languageList").slideToggle("slow");
      })
    }
  }
</script>
<style lang="less" scoped>
@import "./index";
</style>
