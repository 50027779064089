/**
 * Created by Sam on 2020/6/11.
 */
import Vue from 'vue'
import App from './components/App'
import "../common/css/index.less";

new Vue({
  el: '#app',
  render: h => h(App)
})
