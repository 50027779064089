<template>
  <div class="fullpage-container container-fluid">
    <div class="row pc-bg hidden-xs" :class="bg">

      <div class="container" style="height: 100%;">
        <div class="row" style="height: 100%;">
          <div class="col-sm-8 desc-box">
            <h3 v-html="text[`page${currentIndex}_title`]"></h3>
            <p v-html="text[`page${currentIndex}_desc`]"></p>

            <div class="row">
              <div class="col-sm-4">
                <a class="ios" href="https://itunes.apple.com/us/app/mico-meet-new-people-chat/id908023218?mt=8">
                  <img src="//image.micoworld.net/a7d144e6046925ebe9d75ef8fcfc2389" alt="">
                </a>
              </div>
              <div class="col-sm-4">
                <a class="android" href="https://play.google.com/store/apps/details?id=com.mico">
                  <img src="//image.micoworld.net/4784bb39114788cc8731b8b59a6bd180" alt="">
                </a>
              </div>
              <div class="col-sm-4" v-if="apkLinkShown">
                <a class="apk" href="https://cdn.micoworld.net/apk/mico_release.apk">
                  <img src="//image.micoworld.net/44e11e348359f80dd4c023260d003216" alt="">
                </a>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div id="inner-container">
              <template v-for="image, index of language.images">
                <div class="section" :class="'section' + (index + 1)">
                  <img :src="image" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mobile-bg visible-xs-block">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <template v-for="i, index of language.images.length">
            <div class="swiper-slide" :key="index">
              <div :class="'bg' + i"></div>
              <div class="desc-box">
                <h3 v-html="text[`page${i}_title`]"></h3>
                <p v-html="text[`page${i}_desc`]"></p>
              </div>
              <div class="section section1">
                <img :src="language.images[index]" />
              </div>
            </div>
          </template>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="row mobile-dl visible-xs-block">
      <div class="col-xs-4" :class="{'col-xs-offset-2': !apkLinkShown}">
        <a class="ios" href="https://itunes.apple.com/us/app/mico-meet-new-people-chat/id908023218?mt=8">
          <img src="//image.micoworld.net/a7d144e6046925ebe9d75ef8fcfc2389" alt="">
        </a>
      </div>
      <div class="col-xs-4">
        <a class="android" href="https://play.google.com/store/apps/details?id=com.mico">
          <img src="//image.micoworld.net/4784bb39114788cc8731b8b59a6bd180" alt="">
        </a>
      </div>
      <div class="col-xs-4" v-if="apkLinkShown">
        <a class="apk" href="https://cdn.micoworld.net/apk/mico_release.apk">
          <img src="//image.micoworld.net/44e11e348359f80dd4c023260d003216" alt="">
        </a>
      </div>
    </div>
  </div>
</template>
<script>
  import $ from 'jquery'
  import language from '../../common/language'
  export default {
    props: {
      apkLinkShown: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        currentIndex: 1,
        language: language.en.home,
      }
    },
    computed: {
      lang() {
        return window.document.documentElement.lang
      },
      bg() {
        return `bg${this.currentIndex}`
      },
      text() {
        return window.text
      }
    },
    mounted() {
      const _this = this
      $(() => {
        $("#inner-container").fullpage({
          'navigation': true,
          scrollingSpeed: 1000,
          'continuousVertical': true,
          navigationPosition: this.lang === 'ar' ? 'left' : 'right',
          afterLoad(_, index) {
            _this.currentIndex = index
          }
        })
        new Swiper('.swiper-container', {
          loop: true,
          pagination: {
            el: '.swiper-pagination',
          }
        })
      })
    }
  }
</script>
<style lang="less" scoped>
@import "../css/fullpage.less";
</style>
