/**
 * Created by Sam on 2020/6/12.
 */

export default {
  en: {
    home: {
      images: window.location.href.match(/micotm/) ? [
        '//image.micoworld.net/41de09e8f33c72d06d809151042bb078.png',
        '//image.micoworld.net/48c4bf2f1185c96923594ebfbdedd3b6.png',
        '//image.micoworld.net/48d25fa0ac42b2e4066b506b350efbc0.png',
        '//image.micoworld.net/17b0ebc36b3adb2de0a4f2675386fb19.png',
        '//image.micoworld.net/9d394521e8d7843d2bf3b5b767929eb6.png'
      ] : [
        // '//cdn.micoworld.net/upload/7d9ba871b2744d43aa79a044cf6a382d.png',
        // '//image.micoworld.net/fe37026c691897d0a5257f78023a5e1e.png',
        '//image.micoworld.net/745bbe537a05b3b918d29d5fdbe0e439.png',
        // '//cdn.micoworld.net/upload/fc5eb137a00e45678e0ffd7ae54ca32d.png',
        // '//cdn.micoworld.net/upload/b99e10f2ca8b4bd2b434fecee81b2926.png',
        '//image.micoworld.net/e4c2796641e67c35609ae7b79a9e1a58.png',
        // '//cdn.micoworld.net/upload/6340f3baa15349e388788393d42f442e.png',
        // '//image.micoworld.net/f7bc5052692e212a109b05a2307fa10e.png',
        '//image.micoworld.net/35baca7f400f37150dde056e0ee5397c.png',
        // '//cdn.micoworld.net/upload/b9b3bd5218394e31a192dd88b456dab6.png'
        '//image.micoworld.net/a338af9430cfd36e16aae1b709f79d6c.png'
      ]
    }
  }
};
